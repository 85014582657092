export default defineNuxtRouteMiddleware(() => {
  if (import.meta.server) return

  const { shipping, delivery } = useOrder()

  if (shipping.value && delivery.value) return

  const user = useAuth().data.value!

  return navigateTo(
    useLocalePath()(
      user.type === 'guest' ? '/checkout/guest' : '/checkout/shipping',
    ),
  )
})
